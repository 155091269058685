
  /*footer*/
  .footer_land{
    min-height: 392px;
    background-color: black;
    padding: 50px 50px 50px 20px ;
  
  .footer_icons{
    display: grid;
    grid-template-columns: 35% 65%;
    width:100%;
    .icon_hamoye{
      text-align: left;
    }
  }
  

  .footer_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer_speech{
    width:57%;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
  opacity: 80%;
  
  }
  .icons_social{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    gap:25px;
  margin-top: -35px;
    .social{
      width:30px;
      height:30px;
     align-items: center;
    }
  
  }
  
  .iaon-img{
    margin-left: 40px;
    //margin-top: -130px;
    width: 250px;
  }
  .partner_foot{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  .footer_titi{
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  opacity: 80%;
  margin-bottom: 20px;
  cursor: pointer;
  }
  
  .footer_titi2{
    font-size: 16px;
    font-weight: 500; opacity:80%;;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    opacity: 80%;
    margin-bottom: 10px;
   cursor:pointer;
  }
  .footer_titi3{
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin: 10px 10px 0px 10px;
   
  }
  }
  .bordera{
    border: 1px solid #FFFFFF;
    opacity: 50%;
  }
  .news{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:20px;
  
    .subscribe{
      font-size: 16px;
      font-weight: 400;
      font-style: italic;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      opacity: 80%;
      color: white;
      margin-top: 20px;
      }
    .newsleta_input{
      position: relative;
      display: inline-block;
    .newsleta{
     padding: 14px 50px 14px 12px;
     border-radius: 8px;
     width: 300px;
     border: none;
    }
    .newsleta::placeholder{
      color: #7F90A7;
  font-size: 16px;
  font-weight: 500; opacity:80%;;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  
    }
    .newsleta_submit{
      position: absolute;
      right: 0;
      top: 0.25rem;
      margin-top: 0;
    }
    .newsleta_submit:hover{
      background-color: transparent;
    }
  
  }
  
  
  }

  @media screen and (max-width: 1224px) {
    
  /*footer*/
  .footer_land{
    padding: 50px 10px 20px 10px ;
  }
  .footer_icons{
    display: flex;
    flex-direction: column;
  }
  .iaon-img{
  margin-left: 0px;
    margin-bottom: 20px;
  }
  
  .footer_row{
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  .footer_speech{
    width:100%;
  
  }
  .icons_social{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    gap:25px;
  margin-top: 10px;
    .social{
      width:30px;
      height:30px;
     align-items: center;
    }
  
  }
  
  }
}