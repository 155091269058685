/* src/components/LandingPage.css */
.electrify-page{
  display: flex;
  flex-direction: column;
  width:100%;
  
/* Apply a list-style-type of "disc" to the ul element to display bullet points */

.pont{
  cursor: pointer;
}
/*styling for navbar*/
.icon-img{
  filter: brightness(100%) invert(100%);
}
.ambug{
  filter: brightness(100%) invert(0%);
}
nav ul li a {
  text-decoration: none;
  color: #000000;

}
.arrow, .rotate-up {
  filter: brightness(100%) invert(100%);
}
main {
  margin: 2rem 80px 100px 80px;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
 
}
a{
  text-decoration: none;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.678);
  border-radius: 22px;
}
.video-iframe:hover {
  /* Hide video title and controls on hover */
  pointer-events: none;
  cursor: pointer;
}
.video{
     right:0%;
     left: 0%;
     top:0%;
      position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; 
     object-fit: cover; 
}
a:hover{
  text-decoration: underline;
}
 
.res, .tra{
  width: 100%;
  background-color: black;
  padding: 12px;
  border-radius: 4px;
  color: white;
} 
  .res:hover , .tra:hover{
    background-color: lightgray;
    width:100%;
    color: rgb(17, 16, 16);
   // margin: 12px 12px 0px -12px;
  }
/*electric facts*/
  .electricfacts{
    background-color: black;
    padding: 50px 80px 50px 50px;
    margin: 0px -80px 0 -80px;
    min-height: 497px;
    display: flex;
    flex-direction: row;
    //justify-content: center;
    .elect{
      display: flex;
      flex-direction: column;
      gap:20px;
      justify-content: center;
      align-items: flex-start;
      justify-self: center ;
    }
    .electrify_title{
       color: white;
      font-size: 36px;
      font-weight: 700;
      text-align: left;
      
    }
    .electrif_img{
      margin-right: 7%;
    }
    .img_electrif{
      min-width:30vw;
   
    }
    .electric_speech{
    font-size: 18px;
    font-weight: 500; 
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    width: 57%;
    }

}

.electricb_speech{
  margin:50px -20px 0 -20px;;
  display: flex;
  flex-direction: column;
  .aelectric_img{
    align-self: center;
    justify-self: center;
    margin-bottom: 50px;
  }
  .img_aelectric{

    width:100%;
  }
  .electrify_title{
   font-size: 36px;
   font-weight: 700;
   text-align: left;
   
 }
 .electric_speech{
  font-size: 18px;
  opacity: 80%;
  font-weight: 500; 
  line-height: 25px;
  letter-spacing: 0px;
  text-align: justify;
  margin-top: 10px;

  }
}

.Researchfacts{
  background-color: black;
  padding: 50px 80px 50px 50px;
  margin: 50px -80px 0 -80px;
  min-height: 399px;
  display: flex;
  flex-direction: row;
  //justify-content: center;
  .elect{
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 100%;
    align-items: flex-start;
  }
  .electrify_title{
     color: white;
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    
  }
  .electrif_img{
    margin-right: 10%;
  }
  .electric_speech{
  font-size: 18px;
  font-weight: 500; 
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
  margin-top: 10px;
  width:98%;
  }
  .bullet-list {
    list-style-type: disc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 2rem;
  
  }
  .bullet-list li {
    flex-basis: 48%; /* Adjust the width as needed */
    margin-bottom: 10px; 
  }
}
#juniordatasci, #juniordataEngineer, #juniordevops, #jdatastory, #jAprogramcord {
  display: none;
  margin-bottom: 80px;
  margin-top: 50px;
}
}


/*@media only screen and (max-width: 1400px) {
  .electrify-page{
  main{
    margin:30px 10px 30px 10px;
  }
  .electricfacts{
    background-color: black;
    padding: 20px 20px 20px 20px;
    margin: 10rem -10px 0 -10px;
  }
  .Researchfacts{
    padding: 20px;
    margin: 50px -10px 0 -10px;
  }
  .electricb_speech{ 
  .career{
    
    margin-top: 3rem;
  }
}
}
}*/
@media screen and (max-width: 1224px) {
  .electrify-page{
    main{
      margin:-130px 20px 30px 20px;
    }
    .electricb_speech{ 
      .career{
        
        margin-top: 7rem !important;
      }
    }
    .electricfacts{
      background-color: black;
      padding: 20px 20px 20px 20px;
      margin: 10rem -20px 0 -20px;
     // min-height: 10px;
      //justify-content: center;
      .elect{
        display: flex;
        flex-direction: column;
        gap:20px;
        justify-content: center;
        align-items: flex-start;
        justify-self: center ;
      }
      .electrify_title{
         color: white;
        font-size: 26px;
        font-weight: 700;
        text-align: left;
        
      }
      .electrif_img{
        margin-right: 10%;
      }
      .electric_speech{
      font-size: 16px;
      font-weight: 500; 
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      color: #fff;
      margin-top: 10px;
      width:70%;
      }
      .electrif_img{
        display: flex;
        justify-self: center;
        margin-right: 20px;
      }
      .img_electrif{
        width:100%;
     
      }

  }
  .electricb_speech{
    margin:50px 10px 0 10px;
    .img_aelectric{

      width:100%;
    }
    .electrify_title{
     font-size: 26px;
     
   }
   .electric_speech{
    font-size: 16px;
  
    }
  }
  .Researchfacts{
    padding: 20px;
    margin: 50px -20px 0 -20px;
    justify-content: center;
    .elect{

  
      align-items: flex-start;
    }
    .electrify_title{
      font-size: 26px;      
    }
    .electrif_img{
      margin-right: 10%;
    }
    .electric_speech{
    font-size: 16px;
    }
  }
}
 
}

@media screen and (max-width: 768px) {
  
  .electrify-page{
    main{
      margin:-130px 20px 30px 20px;
    }
    .bullet-list {
      display: flex;
      flex-wrap: wrap;
 flex-direction: column;
    
    }
    .electricfacts{
      background-color: black;
      padding: 20px 20px 20px 20px;
      margin: 10rem -20px 0 -20px;
      min-height: 10px;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      .elect{
        display: flex;
        flex-direction: column;
        gap:20px;
        justify-content: center;
        align-items: flex-start;
        justify-self: center ;
      }
      .electrify_title{
         color: white;
        font-size: 26px;
        font-weight: 700;
        text-align: left;
        
      }
      .electrif_img{
        margin-right: 10%;
      }
      .electric_speech{
      font-size: 16px;
      font-weight: 500; 
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      color: #fff;
      margin-top: 10px;
      width:100%;
      }
      .img_electrif{
        width:10%;
        display: none;
      }
  }
  .electricb_speech{
    margin:50px 10px 0 10px;
    .img_aelectric{

      width:100%;
    }
    .electrify_title{
     font-size: 26px;
     
   }
   .electric_speech{
    font-size: 16px;
  
    }
  }
  .Researchfacts{
    padding: 20px;
    margin: 50px -20px 0 -20px;
    justify-content: center;
    .elect{

  
      align-items: flex-start;
    }
    .electrify_title{
      font-size: 26px;      
    }
    .electrif_img{
      margin-right: 10%;
    }
    .electric_speech{
    font-size: 16px;
    }
  }
}
}