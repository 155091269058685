$mobile: 425px;
$tablet: 600px;
$laptop: 991px;
$desktop: 1200px;
$large: 1600px;

$primary: #0b084f;
$primaryThin: #6a6e9a;
$bgWhite: #ffffff;
$modalBg: rgba(0, 0, 0, 0.8);
$arrowLight: rgba(162, 198, 255, 1);
$darkGrey: #7d7a7a;

.overal{
  z-index: 999;
   color:red;
}


@mixin breakpoint($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
.contactgrid{
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (max-width: 1000px) {
    display: grid;
  grid-template-columns: 100%;
  }

}


.modalBg {
  &.open {
    position: fixed;
    top: 0;
    right:0%;
    left:0%;
    z-index: 99;
    width: 100%;
    height: 100%;
     // @include fadeUp;

    &::-webkit-scrollbar {
      display: none;
    }
}
&.close {
  display: none
}

  background-color: $modalBg;
}

.contact {
  display: none;
  &.open {
    display: block;
    background: $bgWhite;
    width: 95%;
    height: 95vh;
   padding: 0px;
   overflow: hidden;
   margin: 1% 2.5%;
   @media screen and (max-width: $large) {
   
  }

  @include breakpoint($laptop) {
    margin: 1% 2.5%;
  }
   @media screen and (max-width: $laptop) {
   display: block;
    padding:20px;
  }
  }
}
.form_contact{
  padding-top: 2rem;
  padding-left: 10%;
  height: 100vh;
  overflow: auto;
  &::-webkit-scrollbar {
    height:5px;
    display: none;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 2%;
   }
}
.formHeading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: -1rem;
  margin-left: 2%;;
  width:100%;
  font-size: 22px;
  font-style: italic;
  font-weight: 600;
  line-height: 28px;
  word-spacing: 3px;
  text-align: left;
  color:#02332D;

}
.orpen {
  position: absolute;
  z-index: 1;
  max-width: 100%;
  margin-top: -20px;
  min-height: 60px;
  max-height: 200px; /* Adjust the height as needed */
  width: 100%;
  overflow-y: scroll; /* This makes the content scroll if it exceeds the height */
  border-radius: 0.375rem; /* Rounded corners */
  background-color: white;
  padding: 0.25rem 1rem; /* Adjust padding as needed */
  font-size: 1rem; /* Adjust font size as needed */
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2);
  border: 1px solid #000;
  outline: none; /* Remove the focus outline */
  
}

.con{
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  margin-right: 3px;
  @media screen and (max-width: 768px) {
   margin: -20px 0px 20px 0px;
  }
}
.closeIcon {
  margin-top: -1rem;
  cursor: pointer;
  color: black;
 
  //margin-left: 90%;
  @include breakpoint($laptop) {
    justify-self: end;
  }
}

.form {
  margin-bottom: 100px;
  @include breakpoint($laptop) {
    padding: 2%;
    width:100%;
   // margin-left:-11%;
  } 
  @include breakpoint($laptop) {
    width:100%;
   // margin-left:-11%;
  } 

  input,
  label,
  textarea,
  .greCaptcha {
    font-weight: 500;
    font-family: "AvenirNext-Medium", Helvetica, Arial, Sans-serif;
    color: $primary;
    border: none;
    outline: none;
    width: 100%;
    display: inline-block;
    font-size: 1rem;
  }

  label {
    display: inline-block;
    color: $primaryThin;
    position: relative;
    color:#6A6E9A;
    font-size: 12px;
  }

  .greCaptcha {
    margin-bottom: 1.5rem;
    height: 50px;
    overflow: hidden;
  }

  input {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid $primary;
    margin-bottom: 1.5rem;
  }

  textarea {
    border: 1px solid $primary;
    height: 4.375rem;
    padding: 10px;
    margin: 0.6rem 0 1.5rem;
  }

  .button {
    width: 100%;
    height: 3.125rem;
    font-size: 1rem;
    background-color: $bgWhite;
    outline: none;
    border: 1px solid $primary;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .footer {
    font-size: 0.75rem;
    color: $primaryThin;
  }
}

.errorMessage {
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  top: 1rem;
  color: rgb(255, 123, 0);

  &.desc {
    position: relative;
    display: inline-block;
    top: unset;
  }
}


.inputs {
  @include breakpoint($desktop) {
    width: 87%;
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    justify-content: space-between;

    .country,
    .description {
      grid-column: 1 / span 2;
    }

    .button {
      grid-column: 2 / span 1;
    }

    .greCaptcha {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
    }
  }
}
.imagedjust{

img{
width:100%;
min-height: 100vh;
}
 
  @media screen and (min-width: 1400px) {
    display: block;
  
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }

}
.subrecap{
  display: grid;
  grid-template-columns: 40% 45%;
  gap:10px;
  align-items: center;
  @media screen and (max-width: 1200px) {
    display: grid;
  grid-template-columns: 45% 53%;
  }
  @media screen and (max-width: 768px) {
    display: grid;
  grid-template-columns: 100%;
  }
 // w
  .captcha{
    //width: 50%;
  }
}


