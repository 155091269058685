.rmbug{
  color: black;
  margin-top: 50px;
  cursor: pointer;
}

.header-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 40px 20px 0 60px;
  
.icon-img{
z-index:99;
width: 250px;
//margin-top: -30px;
}

  .icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .arrow {
    transition: transform 0.3s;
  }
  
  .rotate-up {
    transform: rotate(180deg);
  }
/*hamburger and navigation*/
  .hamburger-menu{
    fill:white;
    display: none; 
  cursor: pointer;
  }
.point{
  cursor: pointer;
}

  .menu-icon{
    display: none;
  }
  .hambug_icon{
    display: none;
    flex-direction: row-reverse;
  }
  .ambug{
    filter: brightness(0%) invert(100%);
    width:40px;
   
  }
  h1 {
    font-size: 2rem;
  }
  nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
     align-items: center;
     margin-left: -175px;
  }
    nav ul li {
      display: inline;
      margin-right: 4vw;
      font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 500;
  text-align: left;
    }
  
    nav ul li a {
      text-decoration: none;
      color: #fff;
    
    }
    nav ul li a:hover{
      text-decoration: underline;
    }
      
  


  .workli{
    position: relative;
    display: inline-block;
  .work{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    width:100%;
    cursor: pointer;
  }
  .work_plank{
    position: absolute;
    //background-color: black;
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //gap: 10px;
    //padding: 12px;
    border-radius:4px;
    width:233px;
    cursor: pointer;
    top:150%;
    right: 5%;
    .divider{
      border: 1px solid white;
      width: 100%;
      margin: 1px 0px 1px 0px;
    }
  }
}
@media only screen and (max-width: 1400px) {
    main{
      margin:30px 10px 30px 10px;
    }
  }

@media screen and (max-width: 1224px) {
    /*hamburger and navigation*/
    main{
        margin:-400px 10px 30px 10px;
      }
      
    .title_icon{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: -30px;
      gap:20px;
         }
   
    .workli{
    .work_plank{
      position: unset;
      //background-color: black;
      color:#fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //gap: 10px;
      //padding: 12px;
      border-radius:4px;
      width:233px;
      cursor: pointer;
      top:150%;
      margin-left: -50%;
      margin-bottom: -40px;
      .divider{
        border: 1px solid white;
        width: 100%;
        margin: 1px 0px 1px 0px;
      }
    }
    .res,.tra{
      display: unset;
      background-color: white ;
      color: #000000;
      list-style-type: disc;
    }
  }
    .menu-icon{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .nav-menu {
      display: none;
    }
    .open {
      display: flex;
      flex-direction: column;
    position: fixed;
    width:90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff; 
    z-index: 3; 
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    }
    .hambug_icon{
      display:flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      //gap:100px;
      margin-top: -40px;
    }
    .hamburger-menu {
      display: flex;
  
     //justify-self: flex-end;
    }

    .hamoye-icon2{
      //margin-left: -30px;
    }
    .icon-img2{
      width: 200px;
      margin:80px 0px 40px;
      
//margin-top: -50px;
      filter: brightness(0%) invert(0%);
    }
    nav ul {
      display: flex;
      flex-direction: column;
      gap:40px;
      align-items: flex-start;
      margin-left: 0px;
      //margin-top: -100%;
    }
    nav ul li {
      //margin-right: 4vw;
      font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 500;
  text-align: left;
    }
    nav ul li a {
      color: #000000;
    }
    .res, .tra{
      width: 100%;
      background-color: black;
      color: white;
      padding: 12px;
      margin-left: 50%;
      border-radius: 4px;
    } 
  
 
  
  }

  @media screen and (max-width: 600px) {
    /*hamburger and navigation*/
  
    .title_icon{
      display: flex;
      flex-direction: row;
      align-items: center;
      
    }
    .icon-img{
      width:200px;
      //margin-top: 30px;
    }
    .icon-img2{
    
      margin:80px 30px 40px;
    }
    .ambug{
      width:35px;
    }
    .menu-icon{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .nav-menu {
      display: none;
    }
    .open {
      display: flex;
      flex-direction: column;
    position: fixed;
    width:90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff; 
    z-index: 3; 
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    }
    .hambug_icon{
      display:flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      //gap:100px;
      margin-top: -40px;
    }
    .hamoye-icon2{
      margin-left: -30px;
    }

    nav ul {
      display: flex;
      flex-direction: column;
      gap:40px;
      align-items: flex-start;
      margin-left: 0px;
      //margin-top: -100%;
    }
    nav ul li {
      //margin-right: 4vw;
      font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 500;
  text-align: left;
    }
    nav ul li a {
      color: #000000;
    }
  
   
  }
}