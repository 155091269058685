/* src/components/LandingPage.css */
.landing-page{
  display: flex;
  flex-direction: column;
  width:100%;
  
  .res, .tra{
    width: 100%;
    background-color: black;
    padding: 12px;
    border-radius: 4px;
    color: white;
  } 
    .res:hover , .tra:hover{
      background-color: rgb(255, 255, 255);
      width:100%;
      color: rgb(0, 0, 0);
     // margin: 12px 12px 0px -12px;
    }

main {
  margin: 100px 80px 100px 80px ;
  display: flex;
  flex-direction: column;
  gap:60px !important;
}
.img-trick1{
  display: none ;
}
header {
  position: relative;
  background-size: cover;
  background-position: center;
  color: #fff;
  //padding: 2rem;
  width:100%;
  height:900px;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
 
}
a{
  text-decoration: none !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.678);
  border-radius: 22px;
}
.video-iframe:hover {
  /* Hide video title and controls on hover */
  pointer-events: none;
  cursor: pointer;
}
.video{
     right:0%;
     left: 0%;
     top:0%;
      position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; 
     object-fit: cover; 
}
  
  .article1_co{
    margin: 0 0px 0 20px;
    position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
    
    //flex-direction: row;
    //justify-content: center;
    .child-img{
      margin-right: 0%;
    .child-imgs{
    width:60vw;
    
     } 
    }
  .description {
   
    position: absolute;
  top: 40%;
  left: 23%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1); 
  z-index:1;
  color: white;
  border-radius: 5px;
  width: 664px;
  height: 437px;
  padding:40px 35px 40px 35px;
  text-align: start;
  border-radius: 12px;
  }
  .describe-title{
   // font-family: "Avenir Next";
font-size: 36px;
font-weight: 700;
width:90%;
letter-spacing: 0px;
text-align: left;
margin-bottom: 10px;
  }
  .describe-text{
    //font-family: "Avenir Next";
font-size: 18px;
font-weight: 500;
line-height: 25.9px;
letter-spacing: 0px;
text-align:left;
width:98%;
  }
  }

  .Research{
    text-align: left;
  a{
    text-decoration: none;
    color: inherit;
  }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.678);
      border-radius: 22px;
    }

   

    .research_title{
   
        font-size: 32px;
        font-weight: 700;
        text-align: left;
        
          }
          .research_text{
        font-size: 18px;
        font-weight: 500; opacity:80%;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;
        margin-top: 10px;
        width:85%;
          }       .indv{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
           
          }
          .indv:hover{
            text-decoration: underline;
          }
          .research_atext{
            font-size: 18px;
            font-weight: 500; opacity:80%;
            text-align:start;
            //width:80%;
            //white-space: nowrap;
            margin-right: 10px;
              }
              .research_antext{
                  color:white;
                  margin-bottom: 30px;
                  }
          .research_images{
            margin: 0px 0 0px 1.5%;
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          .reasearch_pair{
            display: flex;
            flex-direction: column;
            gap:50px
          }
     
            .research-img{
           margin-bottom: 15px;
            }
            .research-img:hover {
              transform: scale(1.1); /* Increase the size by 10% */
              box-shadow: 0 0 10px rgba(0, 0, 0, 1); /* Add a shadow */
            }
       
          }
          .program_head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .navigate_prog{
            display: flex;
            flex-direction: row;
            gap:10px;
             cursor: pointer;
          }
          .program_list{
            display: flex;
            flex-direction: row;
            gap: 20px;
           margin: 30px 0 0 1.5%;
  flex-wrap: nowrap;
  overflow-x: auto;

            .program_blocks{
                width: 321px;
            border-radius: 20px;
            padding: 20px;
            background-color: black;
            color:white;
            
            }
            .topix{
                font-size: 18px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0px;
                text-align: left;
                height:50px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .describx{
                font-size: 16px;
                font-weight: 500; 
                line-height: 22px;
                letter-spacing: 0px;
                text-align: left;
                width: 291px;
            }
            .describx2{
              font-size: 16px;
              font-weight: 500; 
              line-height: 22px;
              letter-spacing: 0px;
              text-align: left;
              width: 270px;
          }
            .Learn{
                margin-top: 1px;
                font-size: 16px;
                font-weight: 700;
            }
          }

       
  }
/*Program facts*/
  .programfacts{
    background-color: black;
    padding: 50px 80px 50px 80px;
    margin: 0px -80px 0 -80px;
    min-height: 458px;
    .facts_title{
       color: white;
      font-size: 32px;
      font-weight: 700;
      line-height: 49px;
      letter-spacing: 0px;
      text-align: left;
      
    }
    .facts_text{
    font-size: 18px;
    font-weight: 500; 
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    width: 86%;
    }
    .facts_nos{
 
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      flex-direction: row;
      padding: 30px 0px 30px 0px;
      .facts_nos_styl{
        display: flex;
        flex-direction: column;
        color: #fff;
        align-items: center;
        text-align: center;
        .styl_1{
          font-size: 60px;
          font-weight: 700;
          line-height: 98px;
        letter-spacing: 0px;
        //text-align: center;
        }
        .styl_2{
font-size: 18px;
font-weight: 500; 
line-height: 25px;
letter-spacing: 0px;
//text-align: center;
        }
        .styl_3{
          width:85%;
font-size: 18px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0px;
//text-align: center;
        }
      }
    }

 
  }

  .partners-section1{
    background-color: #6A6E9A;
    margin: 0px 0px 0px 0px;
    padding: 70px 0 70px 0;
    border-radius: 40px;
    position: relative;
    .partners{
      display: flex;
      
      flex-direction:column ;
      justify-content: center;
    
    .partners_title{
font-size: 48px;
font-weight: 700;
line-height: 66px;
letter-spacing: 0px;
text-align: center;
color: #fff;
    }
    .partner_butts{
      font-weight: 700px;
      align-self:center;
      width: 149px;
      height:62px;
      margin-top: 10px;
      border-radius: 8px;
      border-color: white;
      background-color: #fff;
      border: none;
    box-shadow: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: black;
  
    }
    .partner_butts:hover{
      color:white;
      background-color: black;
    }
  

    .union{
      top:0%;
      left:0%;
      position: absolute;
     }
     .union2{
      bottom:0%;
      right:0%;
      position: absolute;
     }
  }

  .partners-section{
   background-color: #F5F6FA;
   justify-content: center;
   margin: 0px -50px 0px -50px;
   padding: 70px 0 70px 0;
 .partners_title{
  font-size: 48px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 30px;
 }

}
.partner_ad{
  width:50%;
  align-self:center;
}
  }
}
@media only screen and (max-width: 1300px) {
  .landing-page{
  main{
    margin:100px 40px 100px 40px;
  }
  .article1_co{
    margin: 0 0px 0 70px;
}

  }

}


@media screen and (max-width: 1224px) {
  .landing-page{
  header {
    width:100%;
    height:900px;
  }
  .video{
    height:50%;
  }
  main{
    margin:-23rem 20px 100px 20px;
  }
  .article1_co{
   // position: unset;
   
  .description{
    //position: unset;
    display: flex;
    flex-direction: column;
   width: 50%;
   min-height: 300px;
   margin-top: 20px;
 margin-left: 2%;
  justify-content: center;
  align-self: center;
  padding:150px 50px 150px 50px;
  order: 1;
  }

  .describe-title{
font-size: 26px;
  }
  .describe-text{
   font-size: 18px;
  }
  }


/*RESEARCH*/
.Research{
  margin-top: 0px;
.research_text{
  font-size: 18px;
  font-weight: 500; opacity:80%;;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
  width:100%;
    }
    .research_atext{
      font-size: 18px;
      font-weight: 500; 
      text-align:center;
       //width:60% !important;
      gap:20px;
        }
    .research_images{

      display:grid;
      grid-template-columns: 50% 50%;
      //flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 20px 0 20px 0;
      overflow-x: auto;
      .reasearch_pair{
        
        gap:10px;
      }

      .indv{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
      }
      .reindv{
       margin-bottom: 50px;
      }
    }
    
    .research_antext{
      display: none;
      }
  }
/*Program facts*/
.programfacts{
  margin: 0px -20px 0 -20px;
  padding: 20px 10px 20px 10px ;
  .facts_title{
   //font-size: 26px;
  }
  .facts_text{
    width: 100%;
    }
    .facts_nos{
 
      display: flex;
      flex-direction: column;
      padding: 30px 0px 30px 0px;
    }
}
.partners{
 
  .partners_title{
    font-size:36px;
  }

}
  }

}
@media screen and (max-width: 1024px) {
  /*hamburger and navigation*/

  .landing-page{
  header {
    width:100%;
    height:900px;
  }
  .video{
    height:50%;
  }

  .article1_co{
   position: unset;
   
  .description{
    position: unset;
    display: flex;
    flex-direction: column;
   width: 80%;
   height:fit-content;
   margin-top: 120px;
   margin-left: 50%;
   margin-bottom: -100px;
  justify-content: center;
  align-self: center;
  padding:20px 20px 20px 20px;
  order: 1;
  }

  .describe-title{
    font-size: 24px;
  }
  .describe-text{
    font-size: 16px;
  }
}


/*RESEARCH*/

/*Program facts*/
.programfacts{
  margin: 0px -20px 0 -20px;
  padding: 20px 10px 20px 10px ;
  .facts_title{
   //font-size: 26px;
  }
  .facts_text{
    width: 100%;
    }
    .facts_nos{
 
      display: flex;
      flex-direction: column;
      padding: 30px 0px 30px 0px;
    }
}
.partners{
 
  .partners_title{
    font-size:36px;
  }

}
  }

}

@media screen and (max-width: 768px) {
  /*hamburger and navigation*/
 
  .landing-page{
  header {
    width:100%;
    height:600px;
  }
  .video{
    height:50%;
  }
  main{
    margin:-230px 20px 60px 20px;
    gap:30px !important
  }

  .article1_co{

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 0px;
   margin: 0px;
 .child-img{
  //position: unset;
  display: flex;
   justify-self: center;
   width:100%;
   margin-right: 0px;
   margin-left: 0px;
   .child-imgs{
    width: 100%;
   }
 }
 .description{
  display: flex;
  flex-direction: column;
 width: 100%;
 height:fit-content;
 margin-top: 20vw;
 margin-left: 100%;
 margin-bottom: -120px;
justify-content: center;
align-self: center;
padding:20px 20px 20px 20px;
order: 1;
}
.img-trick1{
  //position: unset;
  display: none ; 
 }

.describe-title{
  font-size: 24px;
}
.describe-text{
  font-size: 16px;
}

}


/*RESEARCH*/
.Research{
  margin-top: 0px;
  .foback{
    width:30px;
  }
.research_text{
  font-size: 18px;
  font-weight: 500; opacity:80%;;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
  width:100%;
    }
    .research_atext{
      font-size: 18px;
      font-weight: 500; 
      text-align:center;
      width:100%;
      
        }
    .research_images{

      display:flex;
      flex-direction: column;
      align-items: center;
      
      justify-content: center;
      margin: 20px 0 20px 0;
      overflow-x: auto;
      .reasearch_pair{
 
        gap:10px;
      }

      .indv{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        
      }
    }
    
    .research_antext{
      display: none;
      }
  }
/*Program facts*/
.programfacts{
  margin: 30px -20px 0 -20px;
  padding: 20px 10px 20px 10px ;
  .facts_title{
   //font-size: 26px;
  }
  .facts_text{
    width: 100%;
    }
    .facts_nos{
 
      display: flex;
      flex-direction: column;
      padding: 30px 0px 30px 0px;
    }
}
.partners-section1{
  margin-top: 30px;
}
.partners{

  .partners_title{
    font-size:36px;
  }

}
  }

}

@media screen and (max-width: 496px) {
  .landing-page{
  .img-trick{
    //position: unset;
    display: none !important; 
   }
  .article1_co{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    .img-trick1{
      //position: unset;
      display: block !important ; 
     }
  
  .description{
   display: flex;
   flex-direction: column;
  width: 100%;
  height:fit-content;
  margin-top: 350px;
  margin-left: 100%;
  margin-bottom: -300px;
 justify-content: center;
 align-self: center;
 padding:20px 20px 20px 20px;
 order: 1;
 }
 
 .describe-title{
   font-size: 24px;
 }
 .describe-text{
   font-size: 16px;
 }
 
 }
}
}