.termprivacy-page{
  .Navbar{
    height:8rem;
    position: fixed;
    z-index:90;
    width: 100%;
    top:-30px;
    background-color: #f0f0f0;
  }
  .foot{
    padding: -20px;
    position: absolute;
    width:100%;
    margin-left: 0%;
    z-index: 90;
  }
  
  .pont{
    cursor: pointer;
  }
  /*styling for navbar*/
  .icon-img{
    filter: brightness(100%) invert(100%);
  }
  .ambug{
    filter: brightness(100%) invert(0%);
  }
  nav ul li a {
    text-decoration: none;
    color: #000000;
  
  }
  .arrow, .rotate-up {
    filter: brightness(100%) invert(100%);
  }
  main {
    margin: 2rem 80px 100px 80px;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
   
  }
  a{
    text-decoration: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.678);
    border-radius: 22px;
  }
  .video-iframe:hover {
    /* Hide video title and controls on hover */
    pointer-events: none;
    cursor: pointer;
  }
  .video{
       right:0%;
       left: 0%;
       top:0%;
        position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1; 
       object-fit: cover; 
  }
  a:hover{
    text-decoration: underline;
  }
   
  .res, .tra{
    width: 100%;
    background-color: black;
    padding: 12px;
    border-radius: 4px;
    color: white;
  } 
    .res:hover , .tra:hover{
      background-color: lightgray;
      width:100%;
      color: rgb(17, 16, 16);
     // margin: 12px 12px 0px -12px;
    }


.privacy-header {
    position: fixed;
    z-index: 99;
  z-index: 9;
    top: 3rem;
    left: 0;
     width:100%;
     
background-color: white;
    padding: 20px;
    .titl{
        //font-family: ABeeZee;
font-size: 16px;
//font-style: italic;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
margin: 40px 0% 0% 20px;
color: #02323D;
    }
  }
  
.privacy-container {
    display: flex;

  }
  
  .privacy-menu {
    position: fixed;
    width: 25%;
    top: 8rem;
    bottom: 0;
    overflow: auto;
    background-color: #f0f0f0;
    padding: 20px;
    margin: 20px;
    //border-right: 1px solid #ccc;
     border-radius: 12px;
    ul {
      list-style: none;
      padding: 0;
      li {
        cursor: pointer;
        margin-bottom: 30px;
        //font-family: ABeeZee;
font-size: 16px;
//font-style: italic;
font-weight:500;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
color: #02323D;

      }
            a {
        text-decoration: none;
        display: block;
        padding: 5px;
        border-left: 3px solid transparent; /* Start with a transparent border */
        transition: border-color 0.3s; /* Smooth transition for border-color */

        &:hover {
          background-color: white;
          border-radius: 12px;
        }

        &.active {
          border-left-color: #000; /* Add black border to the active link */
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          background-color: white;
          border-radius: 8px;
          padding: 12px;
        }
      }
    }
    .description{
        display: none;
    }
  }
  
  .privacy-content {
    width: 90%;
    padding: 20px;
    margin-left: 28%; 
    margin-top: 8rem;
    ul {
      padding-left: 30px;
      li{
      list-style-type: disc;
   
      
  }
}
    .titl{
        font-family: Avenir Next;
font-size: 16px;
font-style: italic;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
margin-bottom: 30px;
color: #02323D;
    }
    h2 {
      font-size: 18px;
      margin-bottom:20px;
      font-family: Avenir Next;
font-size: 18px;
font-style: italic;
font-weight: 400;
line-height: 25px;
letter-spacing: 0px;
text-align: left;
color: #02323D;

    }
    p {
        margin-bottom: 30px;
      font-size: 16px;
      color: #02323D;    
font-family: Avenir Next;
font-size: 18px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0px;
text-align: left;
    }
  }
}
  
  @media (max-width: 768px) {
    .termprivacy-page{
      .Navbar{
        height:7rem;
        position: fixed;
        z-index:999;
        width: 100%;
        //top:20px;
        background-color: #f0f0f0;
      }
      
    .privacy-content {
      display: none;
      overflow: hidden;
    }
    .foot{
      position: unset;
      display: flex;
    }
    .privacy-menu{
      position: unset;
        margin-left:10px;
        margin-right: 10px;
        margin-top: 10rem;
        width: 95vw;
        .description {
            display: block;
            background-color: white;
            margin-top: 2rem;
            padding:4px;
            border-radius: 8px;
          }
    }
  }
  }

