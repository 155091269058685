.jobss{
    font-size: 18px;
    list-style-type: disc;
    .title_head{
    font-size: 30px;
    margin-bottom: 20px;
    }
    .job_info{
        display: flex;
        flex-direction: column;
        gap:20px;
    }
    .fooz{
        margin-bottom: 20px;
    }
    
ul li{
    list-style-type: disc;
    
}
ul{
    padding-left: 30px;
}

}
