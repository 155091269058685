/*@import 'styles/globals.css';*/
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
  
}
body{
  overflow-x: hidden;
  font-family: "Avenir Next";
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.678);
  border-radius: 22px;
}
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
 
}
