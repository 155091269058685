/* styles.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .overlay-content {
    background-color: white;
    font-size: 16px;
 
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  .overlay-content h2 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  .overlay-content p {
    font-weight: 400;
  }
  button {
    background-color: #000000;
    margin-top: 30px;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .here{
    color: #000000;
  }
  .visual{
    display: flex;
    flex-direction: row;
    justify-self: center;
    justify-content: space-between;

    margin-left: 95%;
    align-items: center;
  }